import { graphql } from "gatsby";
import * as React from "react";
import get from "lodash/get";

// Components
import Layout from "../layout";

// Sections
import {
  BlogPosts,
  Content,
  PersonaHero,
  FeaturedProducts,
} from "../sections/persona";

const sections = {
  BlogPosts: BlogPosts,
  Content: Content,
  Hero: PersonaHero,
  Products: FeaturedProducts,
};

class PersonaTemplate extends React.Component {
  render() {
    const template_data = get(this.props, "data.contentfulTemplatePersona");
    return (
      <Layout
        seo={{
          description: template_data.description ? template_data.description.description : undefined,
          path: template_data.path,
          title: template_data.title,
        }}
      >
        {template_data.sections.map((section, index) => {
          const Section = sections[section.internal.type.replace("ContentfulPersonaSection", "").replace("ContentfulSection", "")];

          return (
            <Section
              data={section}
              key={index}
            />
          );
        })}
      </Layout>
    );
  }
}

export default PersonaTemplate;

export const pageQuery = graphql`
  query PersonaTemplateBypathQuery($path: String!) {
    contentfulTemplatePersona(path: { eq: $path }) {
      path
      title
      description {
        description
      }
      sections {
        ... on Node {
          ... on ContentfulPersonaSectionHero {
            internal {
              type
            }
            id
            heading {
              heading
            }
            subheading
            description {
              description
            }
            image {
              title
              file {
                url
              }
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            contentsTitle
            contentsTable {
              title
            }
            socialLinks {
              type
              url
            }
            ctaLabel
            ctaLink
            productImage{
              title
              gatsbyImageData(
                layout: CONSTRAINED,
                placeholder: BLURRED
              )
            }
            recProductUrl
          }
          ... on ContentfulPersonaSectionContent {
            internal {
              type
            }
            id
            body {
              raw
            }
          }
          ... on ContentfulPersonaSectionProducts {
             internal {
               type
             }
             id
             heading {
               heading
             }
             products {
               title
               description {
                 description
               }
               badge
               image {
                 title
                 gatsbyImageData(
                   layout: CONSTRAINED,
                   placeholder: BLURRED
                 )
               }
               price
               ctaLabel
               ctaLink
             }
           }
          ... on ContentfulPersonaSectionBlogPosts {
            internal {
              type
            }
            id
            blogPosts {
              title
              path
              image {
                title
                gatsbyImageData(
                  layout: FULL_WIDTH,
                  placeholder: BLURRED
                )
              }
              date
            }
          }
        }
      }
    }
  }
`;

// ... on ContentfulPersonaSectionProducts {
//             internal {
//               type
//             }
//             id
//             heading {
//               heading
//             }
//             products {
//               title
//               description {
//                 description
//               }
//               badge
//               image {
//                 title
//                 gatsbyImageData(
//                   layout: CONSTRAINED,
//                   placeholder: BLURRED
//                 )
//               }
//               price
//               ctaLabel
//               ctaLink
//             }
//           }
